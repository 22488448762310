@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

.text-success {
  color: green;
}

.text-error {
  color: red;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  overflow: hidden;
  border-radius: 0.375rem; /* rounded-3xs */
  max-width: 1000px; /* match the max width of the parent container */
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit; /* keep the same border radius */
}

.centered-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px; /* Ajusta el espacio entre los elementos */
}

.centered-footer .footer-links {
  display: flex;
  gap: 36px;
}

.centered-footer .footer-links > div {
  cursor: pointer;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  overflow: hidden;
  border-radius: 0.375rem; /* rounded-3xs */
  max-width: 1000px; /* match the max width of the parent container */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 1); /* Sombra más pronunciada */
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit; /* keep the same border radius */
}

